import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { publicModule } from '@/store/modules/public';
var CustomHead = (function (_super) {
    __extends(CustomHead, _super);
    function CustomHead() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputValue = '';
        return _this;
    }
    Object.defineProperty(CustomHead.prototype, "innerWidth", {
        get: function () {
            return publicModule.innerWidth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomHead.prototype, "lang", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    CustomHead.prototype.search = function () {
        this.$router.push({ name: 'search', query: { value: this.inputValue, name: this.title } });
    };
    __decorate([
        Prop({ default: '' })
    ], CustomHead.prototype, "title", void 0);
    __decorate([
        Prop({ default: '' })
    ], CustomHead.prototype, "subtitle", void 0);
    __decorate([
        Prop({ default: '' })
    ], CustomHead.prototype, "nexttitle", void 0);
    __decorate([
        Prop({ default: '' })
    ], CustomHead.prototype, "routename", void 0);
    __decorate([
        Prop({ required: true })
    ], CustomHead.prototype, "img", void 0);
    __decorate([
        Prop({ default: 120 })
    ], CustomHead.prototype, "height", void 0);
    __decorate([
        Prop({ default: true })
    ], CustomHead.prototype, "showTool", void 0);
    CustomHead = __decorate([
        Component
    ], CustomHead);
    return CustomHead;
}(Vue));
export default CustomHead;
