import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import { systemModule } from '@/store/modules/system';
import { aboutModule } from '@/store/modules/about';
import { Message } from 'element-ui';
var JobApply = (function (_super) {
    __extends(JobApply, _super);
    function JobApply() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = {
            recruit_id: '',
            fileName: ''
        };
        _this.message = {};
        _this.backspace = /\s/;
        _this.email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        _this.phonephabetNumber_ = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        _this.rules = {
            name: {
                required: true,
                message: _this.$t('jobApply.message'),
                trigger: 'blur'
            },
            gender: {
                required: true,
                message: _this.$t('jobApply.message'),
                trigger: 'blur'
            },
            phone: [
                { required: true, message: _this.$t('jobApply.message'), trigger: 'blur' },
                { validator: _this.phoneCheck, trigger: 'blur' }
            ],
            email: [
                { required: true, message: _this.$t('jobApply.message'), trigger: 'blur' },
                { validator: _this.emailCheck, trigger: 'blur' }
            ],
            fileName: {
                required: true,
                message: _this.$t('jobApply.message'),
                trigger: 'blur'
            }
        };
        return _this;
    }
    JobApply.prototype.phoneCheck = function (rule, value, callback) {
        if (value) {
            if (this.backspace.test(value)) {
                return callback(this.$t('jobApply.backspace'));
            }
            else if (!this.phonephabetNumber_.test(value)) {
                return callback(this.$t('jobApply.phonephabet'));
            }
            callback();
        }
        else {
            callback();
        }
    };
    JobApply.prototype.emailCheck = function (rule, value, callback) {
        if (this.backspace.test(value)) {
            return callback(this.$t('jobApply.backspace'));
        }
        else if (!this.email.test(value)) {
            return callback(this.$t('jobApply.email'));
        }
        callback();
    };
    JobApply.prototype.mounted = function () {
        aboutModule.getJobInfo(this.id);
        this.form.recruit_id = this.id;
    };
    Object.defineProperty(JobApply.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(JobApply.prototype, "jobInfo", {
        get: function () {
            return aboutModule.jobInfo;
        },
        enumerable: false,
        configurable: true
    });
    JobApply.prototype.onChnage = function (file) {
        var isLit200kb = file.size / 1024 < 200;
        if (!isLit200kb) {
            this.$message({
                message: this.$t('jobApply.uploadlimit'),
                type: 'error'
            });
        }
        else {
            this.form.fileName = file.name;
        }
        return isLit200kb;
    };
    JobApply.prototype.onSuccess = function () {
        this.$message({
            message: this.$t('jobApply.uploadSuccess'),
            type: 'success'
        });
        this.reset();
        this.message.close();
    };
    JobApply.prototype.submit = function () {
        var _this = this;
        this.$refs.form.validate(function (valid) {
            if (valid) {
                _this.message = Message({
                    message: _this.$t('jobApply.uploading'),
                    type: 'info',
                    duration: 0
                });
                _this.$refs.upload.submit();
                setTimeout(function () {
                    _this.reset();
                }, 1000);
            }
        });
    };
    JobApply.prototype.reset = function () {
        this.$refs.form.resetFields();
        this.form = {
            recruit_id: '',
            fileName: ''
        };
    };
    __decorate([
        Prop()
    ], JobApply.prototype, "id", void 0);
    JobApply = __decorate([
        Component({
            components: { CustomHead: CustomHead }
        })
    ], JobApply);
    return JobApply;
}(Vue));
export default JobApply;
